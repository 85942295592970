import { error } from "console";
import { permissionsAdmin } from "../configs/acl/PermissionsAdmin";
import { DefaultRoute } from "../router/routes";
import { TypeAbility, TypeUser, permissions, roles } from "./types/user";
import moment from "moment";

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num;

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting: any = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting: any = { month: "short", day: "numeric" };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" };
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};
export const formatDateAndHour = (
  value,
  formatting = {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  } as any
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat("pt-BR", formatting).format(new Date(value));
};
/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem("@realitymed.user");
export const getUserData = () =>
  JSON.parse(localStorage.getItem("@realitymed.user") as any);

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = () => {
  const isAdmin = getIsAdmin();
  const isMentor = getIsMentor();
  const isStudent = getIsStudent();
  if (isAdmin) return "/admin/home";
  if (isStudent || isMentor) return "/dashboard/home";
  return "/login";
};

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#7367f0", // for selected option bg-color
    neutral10: "#7367f0", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed", // for input hover border-color
  },
});

export const getPermissionsUser = (roles, permissions) => {
  roles.map((item) => {
    if (item.permissions.length > 0) permissions.push(...item.permissions);
  });
  const ability = getAbility(permissions);
  return ability;
};

export const getAbility = (permissions: permissions) => {
  const isAdmin = getIsAdmin();
  const isStudent = getIsStudent();
  const isMentor = getIsMentor();
  if (isAdmin) {
    return permissionsAdmin;
  }
  const abilityDefault =
    isStudent || isMentor
      ? { action: "read", subject: "home" }
      : { action: "read", subject: "adminHome" };
  if (permissions.length > 0) {
    const abilityRead: any = [];
    let ability: TypeAbility = permissions.map((item) => {
      abilityRead.push({ action: "read", subject: item.subject });
      return { action: item.action, subject: item.subject };
    });
    ability.push(abilityDefault);
    if (abilityRead.length > 0) {
      ability = ability.concat(abilityRead);
    }
    return ability;
  }
  return [abilityDefault];
};
export const getIsAdmin = () => {
  const role: roles | null = getRolesUser() ?? null;
  if (role == null) return null;
  const isAdmin = role.find((item) => item.name == "Administrador");
  if (isAdmin) return true;
  return false;
};
export const getIsMentor = () => {
  const role: roles | null = getRolesUser() ?? null;
  if (role == null) return null;
  const isMentor = role.find((item) => item.name == "Professor/Mentor");
  if (isMentor) return true;
  return false;
};
export const getIsStudent = () => {
  const role: roles | null = getRolesUser() ?? null;
  if (role == null) return null;
  return role.some((item) => item.name == "Estudante");
};

export const getRolesUser = () => {
  const user: TypeUser = getUserData();
  if (user == null) return null;
  const roles: roles = user.roles;
  return roles;
};
export const getNameRoles = (roles: roles) => {
  let nameRole: string = "";
  if (roles == null) return null;
  roles.map((item, index) => {
    if (index == 0) nameRole += item.name;
    if (index == 3) nameRole += `...`;
    if (index == 1 || index == 2) nameRole += `/${item.name}`;
  });
  return nameRole;
};
export const getNamePermissions = (permissions) => {
  let namePermissions: string = "";
  if (permissions == null) return null;
  if (permissions.length == 0) return "Nenhuma permissão";
  permissions.map((item, index) => {
    if (index == 0) namePermissions += item.description;
    if (index >= 1) namePermissions += ` - ${item.description}`;
  });
  return namePermissions;
};
export const formatNumberByWhatsapp = (number: string) => {
  number = number.replaceAll("-", "");
  number = number.replaceAll("(", "");
  number = number.replaceAll(")", "");
  number = number.replaceAll(" ", "");
  return number;
};

export const formatErrorIsObject = (error) => {
  let message: string = "";
  if (Object.entries(error).length == 0) return null;
  Object.entries(error).forEach(([key, value]: any, index) => {
    if (typeof value == "object") {
      if (index == 0) message += value[0];
      if (index >= 1) message += ` - ${value[0]}`;
    }
  });
  return message;
};

export const convertStringInNumber = (value) => {
  value = value.replaceAll(".", "");
  value = value.replaceAll(",", ".");
  return parseFloat(value);
};
export const formatDateInSafari = (value) => {
  if (/Safari/.test(navigator.userAgent)) {
    return moment(value).format("YYYY/MM/DD hh:mm:ss");
  }
  return moment(value).format("YYYY/MM/DD hh:mm:ss");
};

export const toSeconds = (timeStr) => {
  const [hours, minutes, seconds] = timeStr.split(":").map(Number);
  return hours * 3600 + minutes * 60 + seconds;
};
interface ITransformTime {
  hours?: number;
  minutes?: number;
  seconds?: number;
}
export const transformTime = ({ hours, minutes, seconds }: ITransformTime) => {
  let hoursFormat = "00",
    minutesFormat = "00",
    secondsFormat = "00";

  if (hours) {
    hoursFormat = hours > 9 ? `${hours}` : `0${hours}`;
  }
  if (minutes) {
    minutesFormat = minutes > 9 ? `${minutes}` : `0${minutes}`;
  }
  if (seconds) {
    secondsFormat = seconds > 9 ? `${seconds}` : `0${seconds}`;
  }
  return `${hoursFormat}:${minutesFormat}:${secondsFormat}`;
};
